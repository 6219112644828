// fontend/assets/scss/framework/
@import '../framework';

// fontend/assets/scss/common/
@import '../common/header';

// fontend/assets/scss/_components/
@import '../_components/listing_video_inline';

// fontend/assets/scss/profiles/
@import '../profiles/index';
// @import 'profiles/sign_up';

// fontend/assets/scss/video/
@import '../video/index';

// fontend/assets/scss/_themes/
@import '../_themes/superbe/index';

.module-footer {
  svg.main-logo-superbe {width: 160px; height: 40px;}
  svg.main-logo-rawerotic {width: 160px; height: 40px;}
}

.field {
  border-color: #fff;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 0;
}

.inputs-modals {
  background: #000;
  border-top: none;
  margin-top: 0;
}

.btn {
  border-radius: 0;
}

nav .a i.icon,
nav .a svg.icon {
  color: #fff;
}

.-g-spb-card-icon {
  width: 17px;
  height: 17px;
}

.theme-superbe .-home-min-item {
  color: #ffe5f1;
}

.-g-spb-card-h1 {
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  padding-top: 0;
  height: 24px;
  line-height: 24px;
  font-weight: 600;
  color: gray;
  text-transform: uppercase;
}

.-g-spb-card-h1:hover {
  opacity: .6;
}
.-g-spb-card-h2 {
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  height: 14px;
  line-height: 14px;
  font-weight: 400;
  color: gray;
  text-transform: uppercase;
}

.h1, h1 {
  font-size: 26px;
  line-height: 26px;
  font-weight: 600;
}

.module-header {
  //background: #26282b !important;
}

.nav .a strong {
  font-weight: 600 !important;
}

.popup-age-superbe .-pla-button,
.-f-letter-form .-f-letter-button {
  background: var(--primary_color);
}

.popup-age-superbe .-pla-button:hover {
  background: var(--primary_color_darker);
}

.module-notifications .btn.bordered {
  color: #fff;
}

.renew-form label.label-agree .checkbox:checked + span:before {
  background-color: var(--primary_color) !important;
}

.module-header_buttons .btn.btn-two {
  background: var(--primary_color);
}

.popup-upgrade_yellow_bar .btn.btn-yellow-bar {
  background: #000 !important;
  color: #fff !important;
}

.-g-small-view-all {
  color: #ffe5f1;
}

.theme-superbe .btn.button-color-sign-up-page,
.theme-superbe .-san-btn-main {
  background: var(--primary_color);
  color: #000;
}

.theme-superbe .btn.button-color-sign-up-page:hover,
.theme-superbe .-san-btn-main:hover {
  background: var(--primary_color_darker);
}

// RESPONSIVE - CUSTOM
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media screen and (min-width: 1000px) and (max-width: 1048px) {
  .module-header_logo svg.main-logo-superbe {width: 160px !important;}
  .module-header_logo svg.main-logo-rawerotic {width: 160px !important;}
}

// RESPONSIVE - Mobile
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RESPONSIVE //
@media #{$mobile} {
  .h1, h1 {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
  }
}

@media (min-width: 1000px) {
  .section-boxed {
    max-width: 2500px !important;
  }

  .section-boxed.sides {
    padding: 0 40px !important;
  }
}

.section-boxed.sides.small {
  max-width: 740px !important;
}






